import type { CovariateDef, Endpoint, JobId, KeyParam, ParcelArray, ParcelPathParam, PresignedUrl, RemoteFileInfo, TeamParam } from '$lib/types/types';
import type { DescribeJobDefinitionsCommandOutput } from '@aws-sdk/client-batch';
import type { DescribeImagesCommandOutput } from '@aws-sdk/client-ecr';
import type { PresignedPost } from '@aws-sdk/s3-presigned-post';
import type { RestEndpointMethodTypes } from "@octokit/plugin-rest-endpoint-methods";


export const EndpointCollection = {
  client: {
    // Parcels
    delete_file: {
      method: 'POST', url: 'client/delete_file'
    } as Endpoint<TeamParam & ParcelPathParam & KeyParam>,
    list_files: {
      method: 'POST', url: 'client/list_files'
    } as Endpoint<TeamParam & ParcelPathParam, RemoteFileInfo[]>,
    list_parcels: {
      method: 'POST', url: 'client/list_parcels'
    } as Endpoint<TeamParam, ParcelArray>,
    presigned_download_url: {
      method: 'POST', url: 'client/presigned_download_url'
    } as Endpoint<TeamParam & ParcelPathParam & KeyParam, PresignedUrl>,
    presigned_upload_url: {
      method: 'POST', url: 'client/presigned_upload_url'
    } as Endpoint<TeamParam & ParcelPathParam & KeyParam & { type: string }, PresignedPost>,
    run_parcel: {
      method: 'POST', url: 'client/run_parcel'
    } as Endpoint<TeamParam & ParcelPathParam, JobId>,

  },

  //Admin
  admin: {
    list_commits: {
      method: 'GET', url: 'admin/list_commits',
    } as Endpoint<null, RestEndpointMethodTypes["repos"]["listCommits"]["response"][]>,
    list_batch_job_definitions: {
      method: 'GET', url: 'admin/list_batch_job_definitions',
    } as Endpoint<null, DescribeJobDefinitionsCommandOutput['jobDefinitions']>,
    list_ecr_builds: {
      method: 'GET', url: 'admin/list_ecr_builds',
    } as Endpoint<null, DescribeImagesCommandOutput['imageDetails']>,
  },

  // Others
  list_covariates: {
    method: 'POST', url: 'list_covariates'
  } as Endpoint<{ covariate_prefix: string }, CovariateDef[]>,
};
