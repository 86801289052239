import { writable, get } from 'svelte/store';
import type { Writable } from 'svelte/store';

import { config } from '$lib/config';
import { debug } from '$lib/debug';

export const loading: Writable<boolean> = writable(false);
export const loading_entries: Writable<any> = writable({});

let loading_count = 0;
let cancelling_timeout_id = null;

export const actions = {
  start_loading: (entry: string, detail?: any) => {
    loading_count++;
    add_entry(entry, detail);

    if (cancelling_timeout_id) {
      loading_debug('loading start: clear previous cancelling_timeout');
      clearTimeout(cancelling_timeout_id);
      cancelling_timeout_id = null;
    }
    loading.set(true);
    loading_debug('loading start: count');
  },
  stop_loading: (entry: string | 'unknown', detail?: any) => {
    loading_count--;
    delete_entry(entry, detail);

    if (loading_count === 0) {
      loading_debug('loading stop: all stop scheduled after timeout');
      cancelling_timeout_id = setTimeout(() => {
        loading.set(false);
        loading_debug('loading stop: all stopped (timeout done)');
      }, config.ui.loading_notification_overlap_ms);
    } else {
      loading_debug('loading stop: still loading with count');
    }
  },
};

function add_entry(entry: string, detail?) {
  const got_entries = get(loading_entries);
  got_entries[loading_entry(entry, detail)] = true;
  loading_entries.set(got_entries);
}

function delete_entry(entry: string, detail?) {
  const got_entries = get(loading_entries);
  delete got_entries[loading_entry(entry, detail)];
  loading_entries.set(got_entries);
}

function loading_debug(msg: string) {
  debug(msg, loading_count, get(loading_entries));
}

export function loading_entry(entry: string, detail?) {
  return detail ? `${entry}_${detail}` : entry;
}